import { useState, useEffect } from 'react'
import './KentekenErrorMessage.scss'
import Modal from '../Modal/Modal.js'
import Button from '../Button/Button.js'

const KentekenErrorMessage = () => {
  const [isOpen, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [type, setType] = useState('')

  useEffect(() => {
    function handleEvent (e) {
      setMessage(e.detail.message)
      setType(e.detail.type)
      setOpen(true)
    }
    window.addEventListener('showKentekenErrorMessage', handleEvent)
    return () => window.removeEventListener('showKentekenErrorMessage', handleEvent)
  }, [])

  function handleModalClose () {
    setOpen(false)
  }

  return (
    <Modal open={isOpen} closeModal={handleModalClose} title={`Foutmelding ${type}`}>
      <div className='ErrorMessage'>
        <div className='ErrorMessage-modal-error'><p>{message || ''}</p></div>
        <p>Probeer het opnieuw met een ander kenteken.</p>
        <div className='ErrorMessage-modal-buttons'>
          <Button onClick={handleModalClose}>Sluiten</Button>
        </div>
      </div>
    </Modal>
  )
}

export default KentekenErrorMessage
